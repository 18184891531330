import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";

// import Navbar from "./components/HomePage/Navbar";
// import HomePage from "./components/HomePage/HomePage";
// import CompaniesPage from "./components/CompaniesPage";
// import Register from "./components/Auth/Register";
// import ValidateEmail from "./components/Auth/ValidateEmail";
// import ForgotPassword from "./components/Auth/ForgotPassword";
// import ResetPassword from "./components/Auth/ResetPassword";
// import Login from "./components/Auth/Login";
// import Dashboard from "./components/Dashboard";
// import SuccessPage from "./components/Auth/SuccessPage";
// import AdminPage from "./components/AdminPage";
// import AboutSection from "./components/AboutPage/AboutSection";
// import PartnersPage from "./components/PartnersPage/PartnersPage";
// import InfluencerProgram from "./components/InfluencerProgramSection/InfluencerProgram";
// import FaQsPage from "./components/FaqsSection/FaQsPage";
// import BlogPage from "./components/BlogSection.js/BlogPage";
// import PostPaidBusiness from "./components/PostPaidBusiness/PostPaidBusiness";
// import BusinessForm from "./components/BusinessForm/BusinessForm";
// import Header from "./components/Header/Header";

import loadable from '@loadable/component';
import "./App.css";

const HomePage = loadable(() => import("./components/HomePage/HomePage"))
const CompaniesPage = loadable(() => import("./components/CompaniesPage"))
const Register = loadable(() => import("./components/Auth/Register"))
const ValidateEmail = loadable(() => import("./components/Auth/ValidateEmail"))
const ForgotPassword = loadable(() => import("./components/Auth/ForgotPassword"))
const ResetPassword = loadable(() => import("./components/Auth/ResetPassword"))
const Login = loadable(() => import("./components/Auth/Login"))

const Dashboard = loadable(() => import("./components/Dashboard"))
const SuccessPage = loadable(() => import("./components/Auth/SuccessPage"))
const AdminPage = loadable(() => import("./components/AdminPage"))
const AboutSection = loadable(() => import("./components/AboutPage/AboutSection"))
const PartnersPage = loadable(() => import("./components/PartnersPage/PartnersPage"))
const InfluencerProgram = loadable(() => import("./components/InfluencerProgramSection/InfluencerProgram"))

const FaQsPage = loadable(() => import("./components/FaqsSection/FaQsPage"))
const BlogPage = loadable(() => import("./components/BlogSection.js/BlogPage"))
const PostPaidBusiness = loadable(() => import("./components/PostPaidBusiness/PostPaidBusiness"))
const BusinessForm = loadable(() => import("./components/BusinessForm/BusinessForm"))
const Header = loadable(() => import("./components/Header/Header"))


function App() {
  return (
    <Router>
      <div className="App">
        {/* <Navbar /> */}
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route index element={<HomePage />} />
          <Route path="/business" element={<PostPaidBusiness />} />
          <Route path="/about" element={<AboutSection />} />
          <Route path="/partners" element={<PartnersPage />} />
          <Route path="/influencers" element={<InfluencerProgram />} />
          <Route path="/faqs" element={<FaQsPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/companies" element={<CompaniesPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/validate-email" element={<ValidateEmail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:oobCode" element={<ResetPassword />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/business-form" element={<BusinessForm />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
